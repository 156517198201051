import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import "moment-timezone";

export default ({ event }) => (
  <div className="border-t border-lightestGray py-4">
    <div className="flex items-baseline">
      <div className="flex-none font-semibold text-theme1-accent text-xs uppercase w-10 text-center pr-2 md:text-sm">
        {moment(event.dateTime).tz("America/Denver").format("ddd")}
      </div>
      <div className="font-sans font-semibold leading-6 mb-1 text-lg md:text-xl">
        <Link
          to={`/events/${event.slug}`}
          dangerouslySetInnerHTML={{ __html: event.title }}
        />
      </div>
    </div>
    <div className="flex items-baseline">
      <div className="flex-none font-semibold text-lg text-theme1-accent w-10 text-center pr-2 md:text-xl">
        {moment(event.dateTime).tz("America/Denver").format("DD")}
      </div>
      <div className="">
        <div className="font-sans font-extralight leading-5 mb-1 text-lg text-midGray md:text-xl">
          {event.description}
        </div>
        <div className="flex flex-start text-darkBlue">
          <div className="mr-4">
            {moment(event.dateTime).tz("America/Denver").format("h:mma")}
          </div>
          <div>{event.location || event.address}</div>
        </div>
      </div>
    </div>
  </div>
);
