import React from 'react'
import EventPreview from './event-preview'

export default ({ month, events }) => {
  return (
    <div>
      <div className="font-semibold font-serif pt-2 text-2xl text-darkBlue md:text-3xl">{month}</div>
      {events.map(({ node }) => {
        return (
          <EventPreview event={node} key={node.id}/>
        )
      })}
    </div>
  )
}