import React from "react";
import { Link, graphql } from "gatsby";
import get from "lodash/get";
import moment from "moment";
import "moment-timezone";
import HelmetWrapper from "../components/helmet-wrapper";
import Layout from "../components/layout";
import EventMonthGroup from "../components/event-month-group";

const HOSTING = "hosting";
const ATTENDING = "attending";

class EventsIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      segmentControl: HOSTING,
    };

    this.allEvents = get(this, "props.data.allContentfulEvent.edges");
    this.allAttending = get(this, "props.data.allContentfulAttending.edges");

    this.toggleSegment = this.toggleSegment.bind(this);
  }

  monthify(items) {
    const monthsObject = {};

    items.forEach((item) => {
      const itemMonth = moment(item.node.dateTime)
        .tz("America/Denver")
        .format("MMMM");
      if (monthsObject[itemMonth]) {
        monthsObject[itemMonth].push(item);
      } else {
        monthsObject[itemMonth] = [item];
      }
    });

    return monthsObject;
  }

  toggleSegment(e) {
    this.setState({ segmentControl: e.target.id });
  }

  findUpNext(events, attending) {
    if (this.state.segmentControl === HOSTING) {
      return events.length ? events[0].node : null;
    }

    if (this.state.segmentControl === ATTENDING) {
      return attending.length ? attending[0].node : null;
    }

    return null;
  }

  renderEventsByMonth(type) {
    let eventMonthGroups = [];
    const eventsByMonth = this.monthify(type);

    if (!Object.keys(eventsByMonth).length)
      return <div className="my-4">No upcoming events.</div>;

    for (const month in eventsByMonth) {
      eventMonthGroups.push(
        <EventMonthGroup
          key={month}
          month={month}
          events={eventsByMonth[month]}
        />
      );
    }
    return eventMonthGroups;
  }

  render() {
    // we need to make sure this is the start of the day in the mountain time zone,
    // since the events are also dated in mountain time
    const currentDate = moment().tz("America/Denver").startOf("day").format();

    const events = this.allEvents.filter(
      (event) => event.node.dateTime >= currentDate
    );

    const attending = this.allAttending.filter(
      (event) => event.node.dateTime >= currentDate
    );

    const upNext = this.findUpNext(events, attending);
    const segmentControl = this.state.segmentControl;
    const type = segmentControl === HOSTING ? events : attending;

    return (
      <Layout location={this.props.location}>
        <div className="p-4">
          <HelmetWrapper pageTitle="Events" />
          <div className="md:max-w-5xl md:mx-auto">
            <div>
              <button
                className={`segment-button ${
                  segmentControl === HOSTING ? "segment-button-active" : ""
                }`}
                id={HOSTING}
                onClick={this.toggleSegment}
              >
                Hosting
              </button>
              <button
                className={`segment-button ${
                  segmentControl === ATTENDING ? "segment-button-active" : ""
                }`}
                id={ATTENDING}
                onClick={this.toggleSegment}
              >
                Attending
              </button>
            </div>
            {upNext && (
              <div className="bg-lightCool font-semibold text-sm p-4 my-4 rounded-2xl">
                <div className="font-sans text-theme1-accent tracking-wider uppercase">
                  Up Next
                </div>
                <div className="flex justify-between items-center">
                  <div className="mb-2 text-4xl md:text-6xl">
                    <Link
                      to={upNext.slug}
                      dangerouslySetInnerHTML={{ __html: upNext.title }}
                    />
                  </div>
                  <div className="text-theme1-accent">
                    <Link to={upNext.slug}>→</Link>
                  </div>
                </div>
                <div className="font-thin leading-6 mb-2 text-2xl md:text-4xl">
                  {upNext.description}
                </div>
                <div className="flex">
                  <div className="mr-4">
                    {moment(upNext.dateTime)
                      .tz("America/Denver")
                      .format("MMMM D, h:mma")}
                  </div>
                  <div>{upNext.location || upNext.address}</div>
                </div>
              </div>
            )}
            {this.renderEventsByMonth(type)}
          </div>
        </div>
      </Layout>
    );
  }
}

export default EventsIndex;

export const pageQuery = graphql`
  query EventsIndexQuery {
    allContentfulEvent(
      sort: { fields: dateTime, order: ASC }
      filter: { listed: { eq: true } }
    ) {
      edges {
        node {
          address
          dateTime
          description
          id
          listed
          location
          slug
          title
        }
      }
    }
    allContentfulAttending(sort: { fields: dateTime, order: ASC }) {
      edges {
        node {
          address
          dateTime
          description
          id
          location
          slug
          title
        }
      }
    }
  }
`;
